import { Component, OnDestroy, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import { BreadcrumbService } from "src/app/shared/services/breadcrumb.service";
import { FilterService } from "src/app/shared/services/filter.service";
import { RateService } from "src/app/shared/services/rate.service";

@Component({
  selector: "app-rate-master",
  templateUrl: "./rate-master.component.html",
  styleUrls: ["./rate-master.component.scss"],
})
export class RateMasterComponent implements OnInit {
  activeRateTab: any = 0;
  CurrentPage: any = 0;
  CurrentRatePage: any = 0;
  EndValue: any = 20;
  startValue: any = 0;
  vendorRateList: any;
  masterRateList: any;
  filterObj: any;
  sortRecords: any = 20;
  firstTimeRateLoad: boolean = true;
  filterGdp: any = null;
  filterFamily: any = null;
  filterExperience: any = null;
  filterGrade: any = null;
  filterTitle: any = null;
  gdpList: any = [];
  vendorList: any = [];
  vendor_rate_column = [
    "GDP",
    "Job Title",
    "Job Family",
    "Experience Level",
    "Grade",
    "Onsite Rate",
    "Domestic Rate",
    "Offshore Rate",
    "Nearshore Rate",
  ];
  market_rate_column = [
    "Job Title",
    "Onsite Rate (Min - Max)",
    "Domestic Rate (Min - Max)",
    "Offshore Rate (Min - Max)",
    "Nearshore Rate(Min - Max)",
  ];
  private subscription: Subscription;

  constructor(
    private spinner: NgxSpinnerService,
    private rateService: RateService,
    private filterService: FilterService,
    private breadCrumbService: BreadcrumbService
  ) {}

  ngOnInit(): void {
    this.getVendorRateList();
    this.getMasterRateList();
    this.getVendorList();
    let breadCrumb = [
      { label: "Masters", url: "/prism/masters" },
      { label: "Rate Master", url: "/rate/rate-master" },
    ];
    this.breadCrumbService.setBreadcrumb(breadCrumb);
  }

  getVendorList() {
    this.filterService.getVendor().subscribe((resp) => {
      this.vendorList = resp;
    });
  }

  getVendorRateList() {
    this.spinner.show();
    this.vendorRateList = [];
    this.rateService
      .getFilteredVendorData(
        this.startValue,
        this.EndValue,
        this.filterGdp,
        this.filterFamily,
        this.filterExperience,
        this.filterGrade,
        this.filterTitle
      )
      .subscribe((res: any) => {
        this.spinner.hide();
        this.vendorRateList = res;
      });
  }

  getMasterRateList() {
    this.spinner.show();
    this.rateService
      .getFilteredMarketData(this.startValue, this.EndValue, this.filterTitle)
      .subscribe((res: any) => {
        this.spinner.hide();
        this.masterRateList = res;
      });
  }

  getMasterRateRecords() {
    if (this.CurrentPage > 0) {
      this.startValue =
        (parseInt(this.CurrentPage) - 1) * parseInt(this.sortRecords);
    } else {
      this.startValue = 0;
    }
    this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);

    if (!this.firstTimeRateLoad) {
      if (this.activeRateTab == 1) {
        this.getMasterRateList();
      }
    }
    this.firstTimeRateLoad = false;
  }

  getRateRecords(type: any) {
    if (this.activeRateTab == type) {
      if (this.CurrentRatePage > 0) {
        this.startValue =
          (parseInt(this.CurrentRatePage) - 1) * parseInt(this.sortRecords);
      } else {
        this.startValue = 0;
      }
      this.EndValue = parseInt(this.startValue) + parseInt(this.sortRecords);

      if (!this.firstTimeRateLoad) {
        if (this.activeRateTab == 0) {
          this.getVendorRateList();
        } else if (this.activeRateTab == 1) {
          this.getMasterRateList();
        }
      }

      this.firstTimeRateLoad = false;
    }
  }

  changeRateTab(event: any) {
    this.activeRateTab = event.index;
    this.CurrentRatePage = 0;
    this.EndValue = 20;
    this.startValue == 0;
    // if(this.activeRateTab == 0 && this.vendorRateList?.count == 0){
    //   this.getVendorRateList();
    // }else if(this.activeRateTab == 1 && this.masterRateList?.count == 0){
    //   this.getMasterRateList();
    // }
  }

  search(changedInput: any) {
    if (changedInput !== "jobTitle") {
      this.filterTitle = null;
    }
    if (changedInput !== "jobFamily") {
      this.filterFamily = null;
    }
    if (changedInput !== "experience") {
      this.filterExperience = null;
    }
    if (changedInput !== "grade") {
      this.filterGrade = null;
    }
    if (changedInput !== "gdp") {
      this.filterGdp = null;
    }
    if (this.activeRateTab === 0) {
      this.rateService
        .getFilteredVendorData(
          this.startValue,
          this.EndValue,
          this.filterGdp,
          this.filterFamily,
          this.filterExperience,
          this.filterGrade,
          this.filterTitle
        )
        .subscribe((res: any) => {
          this.vendorRateList = res;
        });
    } else {
      this.rateService
        .getFilteredMarketData(this.startValue, this.EndValue, this.filterTitle)
        .subscribe((res: any) => {
          this.masterRateList = res;
        });
    }
  }
}
