<div class="px-0 pb-3">
  <app-bread-crumb></app-bread-crumb>
</div>
<div class="row">
  <div class="col-sm-2">
    <div class="single-left-pane">
      <app-side-menu></app-side-menu>
    </div>
  </div>
  <div class="col-sm-7">
    <div class="single-center-pane">
      <h5>Rate Master</h5>
      <div class="filter-container mb-2 mt-3">
        <div class="filter-icon">
          <img height="25px" width="25px" src="./assets/images/filter-cp.png" />
        </div>
        <input
          type="text"
          placeholder="Search Job Title..."
          class="form-control text-input"
          [(ngModel)]="filterTitle"
          (ngModelChange)="search('jobTitle')"
          style="
            width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <mat-form-field
          *ngIf="activeRateTab === 0"
          class="w-100 filter-dropdown"
          appearance="outline"
          style="font-size: 12px"
        >
          <mat-select
            [(ngModel)]="filterGdp"
            (ngModelChange)="search('gdp')"
            placeholder="Search GDP..."
          >
            <mat-option value=""></mat-option>
            <mat-option
              *ngFor="let vendor of vendorList"
              value="{{ vendor.id }}"
              >{{ vendor.name }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <input
          *ngIf="activeRateTab === 0"
          type="text"
          placeholder="Search Job Family..."
          class="form-control text-input"
          [(ngModel)]="filterFamily"
          (ngModelChange)="search('jobFamily')"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <input
          *ngIf="activeRateTab === 0"
          type="text"
          placeholder="Search Experience.."
          class="form-control text-input"
          [(ngModel)]="filterExperience"
          (ngModelChange)="search('experience')"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />
        <input
          *ngIf="activeRateTab === 0"
          type="text"
          placeholder="Search Grade..."
          class="form-control text-input"
          [(ngModel)]="filterGrade"
          (ngModelChange)="search('grade')"
          style="
            min-width: 13rem;
            border-radius: 8px;
            font-size: 12px;
            height: 40px;
          "
        />

        
      </div>
      <mat-tab-group
        mat-align-tabs="start"
        class="head"
        (selectedTabChange)="changeRateTab($event)"
      >
        <mat-tab label="GDP Rate ({{ vendorRateList?.count }})">
          <div
            class="custom-table fixed-table-header"
            style="max-height: calc(100vh - 350px)"
          >
            <table class="table table-bordered text-left">
              <thead>
                <tr>
                  <th *ngFor="let column of vendor_rate_column">
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rate of vendorRateList?.records">
                  <td>{{ rate?.vendor_name }}</td>
                  <td>{{ rate?.job_title?.name }}</td>
                  <td>{{ rate?.job_title?.job_family?.name }}</td>
                  <td>{{ rate?.additional_details?.experienceLevel }}</td>
                  <td>{{ rate?.grade }}</td>
                  <td>
                    {{ rate?.onsite_rate === "NaN" ? "-" : rate?.onsite_rate }}
                  </td>
                  <td>
                    {{
                      rate?.domestic_rate === "NaN" ? "-" : rate?.domestic_rate
                    }}
                  </td>
                  <td>
                    {{
                      rate?.offshore_rate === "NaN" ? "-" : rate?.offshore_rate
                    }}
                  </td>
                  <td>
                    {{
                      rate?.nearshore_rate === "NaN"
                        ? "-"
                        : rate?.nearshore_rate
                    }}
                  </td>
                </tr>
              </tbody>
              <ng-container *ngIf="vendorRateList?.count == 0">
                <div
                  class="noRecords d-flex justify-content-center text-center"
                >
                  <span>
                    {{ staticText.common.no_records_found }}
                  </span>
                </div>
              </ng-container>
            </table>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="vendorRateList?.count > 0"
          >
            <div class="p-1">
              <div
                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
              >
                <span class="p-1">Displaying</span>
                <select
                  class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example"
                  [(ngModel)]="sortRecords"
                  (ngModelChange)="getRateRecords(0)"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
                <span class="p-1">{{ vendorRateList?.count }}</span>
                <span class="p-1">records</span>
              </div>
            </div>
            <div class="p-1">
              <ngb-pagination
                [pageSize]="sortRecords"
                [collectionSize]="vendorRateList?.count"
                [maxSize]="2"
                [rotate]="true"
                [(page)]="CurrentRatePage"
                [boundaryLinks]="true"
                (pageChange)="getRateRecords(0)"
              >
              </ngb-pagination>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Market Rate ({{ masterRateList?.count }})">
          <div
            class="custom-table fixed-table-header"
            style="max-height: calc(100vh - 350px)"
          >
            <table class="table table-bordered text-left">
              <thead>
                <tr>
                  <th *ngFor="let column of market_rate_column">
                    {{ column }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let rate of masterRateList?.records">
                  <td>{{ rate?.job_title?.name }}</td>
                  <td>
                    {{ rate?.min_onsite_rate }} - {{ rate?.max_onsite_rate }}
                  </td>
                  <td>
                    {{ rate?.min_domestic_rate }} -
                    {{ rate?.max_domestic_rate }}
                  </td>
                  <td>
                    {{ rate?.min_offshore_rate }} -
                    {{ rate?.max_offshore_rate }}
                  </td>
                  <td>
                    {{ rate?.min_nearshore_rate }} -
                    {{ rate?.max_nearshore_rate }}
                  </td>
                </tr>
              </tbody>
              <ng-container *ngIf="masterRateList?.count == 0">
                <div
                  class="noRecords d-flex justify-content-center text-center"
                >
                  <span>
                    {{ staticText.common.no_records_found }}
                  </span>
                </div>
              </ng-container>
            </table>
          </div>
          <div
            class="d-flex align-items-center justify-content-between"
            *ngIf="masterRateList?.count > 0"
          >
            <div class="p-1">
              <div
                class="d-flex flex-row justify-content-around align-items-center font-13 text-capitalize text-dark"
              >
                <span class="p-1">Displaying</span>
                <select
                  class="form-select form-select-sm table-sort-filter"
                  aria-label="Default select example"
                  [(ngModel)]="sortRecords"
                  (ngModelChange)="getMasterRateRecords(1)"
                >
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="150">150</option>
                  <option value="200">200</option>
                </select>
                <span class="p-1">{{ masterRateList?.count }}</span>
                <span class="p-1">records</span>
              </div>
            </div>
            <div class="p-1">
              <ngb-pagination
                [pageSize]="sortRecords"
                [collectionSize]="masterRateList?.count"
                [maxSize]="2"
                [rotate]="true"
                [(page)]="CurrentPage"
                [boundaryLinks]="true"
                (pageChange)="getMasterRateRecords(1)"
              >
              </ngb-pagination>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="single-right-pane">
      <span class="bubble-top" style="background-color: #ed0000">&nbsp;</span>
      <app-gnews></app-gnews>
    </div>
  </div>
</div>
